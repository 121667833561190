import styled from "@emotion/styled";

export const BoldDisplay05 = styled.p`
  font-weight: 700;
  letter-spacing: -0.7px;
  font-size: 2.5rem; //40px
  line-height: 3.25rem;
`;

export const BoldDisplay04 = styled.p`
  font-weight: 700;
  letter-spacing: -0.7px;
  font-size: 2.25rem; //36px
  line-height: 2.875rem;
`;
export const BoldDisplay03 = styled.p`
  font-weight: 700;
  letter-spacing: -0.7px
  font-size: 2rem; //40px
  line-height: 2.625rem;
`;
export const BoldDisplay02 = styled.p`
  font-weight: 700;
  letter-spacing: -0.7px;
  font-size: 1.75em; //40px
  line-height: 2.375rem;
`;
export const BoldDisplay01 = styled.p`
  font-weight: 700;
  letter-spacing: -0.7px;
  font-size: 25px;
  line-height: 2.19rem;
`;

export const BoldHeadLine02 = styled.p`
  font-weight: 700;
  letter-spacing: -0.7px;
  font-size: 1.38em; //40px
  line-height: 1.88rem;
`;

export const BoldHeadLine01 = styled.p`
  font-weight: 700;
  letter-spacing: -0.7px;
  font-size: 1.25em; //40px
  line-height: 1.75rem;
`;

export const BoldSubHead02 = styled.p`
  font-weight: 700;
  letter-spacing: -0.7px;
  font-size: 1.13em; //40px
  line-height: 1.5rem;
`;

export const BoldSubHead01 = styled.p`
  font-weight: 700;
  letter-spacing: -0.7px;
  font-size: 1em; //40px
  line-height: 1.38rem;
`;

export const BoldSubHeadLong02 = styled.p`
  font-weight: 700;
  letter-spacing: -0.7px;
  font-size: 1.13em; //40px
  line-height: 1.88rem;
`;

export const BoldSubHeadLong01 = styled.p`
  font-weight: 700;
  letter-spacing: -0.7px;
  font-size: 1em; //40px
  line-height: 1.63rem;
`;

export const SemiBoldDisplay05 = styled.p`
  font-weight: 600;
  letter-spacing: -0.7px;
  font-size: 2.5rem; //40px
  line-height: 3.25rem;
`;
export const SemiBoldDisplay04 = styled.p`
  font-weight: 600;
  letter-spacing: -0.7px;
  font-size: 2.25rem; //36px
  line-height: 2.875rem;
`;
export const SemiBoldDisplay03 = styled.p`
  font-weight: 600;
  letter-spacing: -0.7px;
  font-size: 2rem; //40px
  line-height: 2.625rem;
`;
export const SemiBoldDisplay02 = styled.p`
  font-weight: 600;
  letter-spacing: -0.7px;
  font-size: 1.75em; //40px
  line-height: 2.375rem;
`;
export const SemiBoldDisplay01 = styled.p`
  font-weight: 600;
  letter-spacing: -0.7px;
  font-size: 1.56em; //40px
  line-height: 2.19rem;
`;

export const SemiBoldHeadLine02 = styled.p`
  font-weight: 600;
  letter-spacing: -0.6px;
  font-size: 1.38em; //40px
  line-height: 1.88rem;
  @media screen and (max-width: 1024px) {
    font-weight: 600;
    letter-spacing: -0.7px;
    font-size: 1.13em; //40px
    line-height: 1.5rem;
  }
`;

export const SemiBoldHeadLine01 = styled.p`
  font-weight: 600;
  letter-spacing: -0.6px;
  font-size: 1.25em; //20px
  line-height: 1.75rem; //28px
`;

export const SemiBoldSubHead02 = styled.p`
  font-weight: 600;
  letter-spacing: -0.7px;
  font-size: 1.13em; //40px
  line-height: 1.5rem;
`;

export const SemiBoldSubHead01 = styled.p`
  font-weight: 600;
  letter-spacing: -0.7px;
  font-size: 1em; //40px
  line-height: 1.38rem;
`;

export const SemiBoldSubHeadLong02 = styled.p`
  font-weight: 600;
  letter-spacing: -0.7px;
  font-size: 1.13em; //40px
  line-height: 1.88rem;
`;

export const SemiBoldSubHeadLong01 = styled.p`
  font-weight: 600;
  letter-spacing: -0.7px;
  font-size: 1em; //40px
  line-height: 1.63rem;
`;

export const MediumBody02 = styled.p`
  font-weight: 500;
  letter-spacing: -0.6px;
  font-size: 1rem; //40px
  line-height: 1.5rem;
`;

export const MediumBodyLong02 = styled.p`
  font-weight: 500;
  letter-spacing: -0.7px;
  font-size: 1em; //40px
  line-height: 1.75rem;
`;

export const MediumBody01 = styled.p`
  font-weight: 500;
  letter-spacing: -0.5px;
  font-size: 12px; //40px
  line-height: 150%;
`;

export const MediumBodyLong01 = styled.p`
  font-weight: 500;
  letter-spacing: -0.7px;
  font-size: 0.88em; //40px
  line-height: 1.5rem;
`;

export const MediumBodyCaption = styled.p`
  font-weight: 500;
  letter-spacing: -0.7px;
  font-size: 0.75em; //40px
  line-height: 1.13rem;
`;

export const RegularBody02 = styled.p`
  font-weight: 400;
  letter-spacing: -0.7px;
  font-size: 1em; //40px
  line-height: 1.5rem;
  @media screen and (max-width: 1024px) {
    font-weight: 400;
    letter-spacing: -0.7px;
    font-size: 0.88em; //40px
    line-height: 1.25rem;
  }
`;

export const RegularBodyLong02 = styled.p`
  font-weight: 400;
  letter-spacing: -0.7px;
  font-size: 1em; //40px
  line-height: 1.75rem;
`;

export const RegularBody01 = styled.p`
  font-weight: 400;
  letter-spacing: -0.7px;
  font-size: 0.88em; //40px
  line-height: 1.25rem;
`;

export const RegularBodyLong01 = styled.p`
  font-weight: 400;
  letter-spacing: -0.7px;
  font-size: 0.88em; //40px
  line-height: 1.5rem;
`;

export const RegularBodyCaption = styled.p`
  font-weight: 400;
  letter-spacing: -0.7px;
  font-size: 0.75em; //40px
  line-height: 1.13rem;
`;

export const FontSizeValue = {
  _8: "8px",
  _10: "10px",
  _12: "12px",
  _16: "16px",
  _20: "20px",
  _25: "25px",
  _31: "31px",
  _39: "39px",
  _48: "48px",
  _61: "61px",
};

export const ShadowTheme = {
  _2: "0px 2px 2px rgba(0, 0, 0, 0.12)",
  _4: "0px 0px 1px 0px rgba(0, 0, 0, 0.06), 0px 2px 4px 0px rgba(0, 0, 0, 0.13);",
  _6: "0px 1px 1px 0px rgba(0, 0, 0, 0.06), 0px 2px 6px 0px rgba(0, 0, 0, 0.14)",
  _8: "0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 2px 8px 0px rgba(0, 0, 0, 0.14)",
  _12: "0px 2px 6px 0px rgba(0, 0, 0, 0.10), 0px 2px 12px 0px rgba(0, 0, 0, 0.15)",
  _16: "0px 4px 8px 0px rgba(0, 0, 0, 0.10), 0px 2px 16px 0px rgba(0, 0, 0, 0.15)",
  _20: "0px 4px 10px 0px rgba(0, 0, 0, 0.14), 0px 4px 20px 0px rgba(0, 0, 0, 0.16)",
  _24: "0px 4px 12px rgba(0, 0, 0, 0.22), 0px 4px 24px 0px rgba(0, 0, 0, 0.16)",

  /* 24 */
};

export const FontWeightValue = {
  Bold: 700,
  SemiBold: 600,
  Medium: 500,
  Regular: 400,
};

export const LineHeightValue = {
  _140: 140,
  _150: 150,
  Paragraph: 150,
};

export const LetterSpacingValue = {
  _minus05: "-0.5px",
};

export const FontWeightTheme = {
  Heading: FontWeightValue.Bold,
  Label: FontWeightValue.SemiBold,
  ParagraphMedium: FontWeightValue.Medium,
  ParagraphRegular: FontWeightValue.Regular,
};

export const TypographyStyle = {
  heading: {
    bold25: {
      fontWeight: FontWeightTheme.Heading,
      lineHeight: LineHeightValue._140 + "%",
      fontSize: FontSizeValue._25,
      letterSpacing: LetterSpacingValue._minus05,
    },
    bold16: {
      fontWeight: FontWeightTheme.Heading,
      lineHeight: LineHeightValue._140,
      fontSize: FontSizeValue._16,
      letterSpacing: LetterSpacingValue._minus05,
    },
    bold20: {
      fontWeight: FontWeightTheme.Heading,
      lineHeight: LineHeightValue._140,
      fontSize: FontSizeValue._20,
      letterSpacing: LetterSpacingValue._minus05,
    },
    bold31: {
      FontWeight: FontWeightTheme.Heading,
      LineHeight: LineHeightValue._140,
      fontSize: FontSizeValue._31,
      letterSpacing: LetterSpacingValue._minus05,
    },
    bold39: {
      fontWeight: FontWeightTheme.Heading,
      lineHeight: LineHeightValue._140 + "%",
      fontSize: FontSizeValue._39,
      letterSpacing: LetterSpacingValue._minus05,
    },
    bold48: {
      fontWeight: FontWeightTheme.Heading,
      lineHeight: LineHeightValue._140 + "%",
      fontSize: FontSizeValue._48,
      letterSpacing: LetterSpacingValue._minus05,
    },
    bold61: {
      FontWeight: FontWeightTheme.Heading,
      LineHeight: LineHeightValue._140,
      fontSize: FontSizeValue._61,
      letterSpacing: LetterSpacingValue._minus05,
    },
  },
  label: {
    semiBold25: {
      fontWeight: FontWeightTheme.Label,
      lineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._25,
      letterSpacing: LetterSpacingValue._minus05,
    },
    semiBold16: {
      fontWeight: FontWeightTheme.Label,
      //lineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._16,
      letterSpacing: LetterSpacingValue._minus05,
    },
    semiBold20: styled.p`
      font-weight: ${FontWeightTheme.Label},
      line-height: ${LineHeightValue.Paragraph},
      font-size:${FontSizeValue._20},
      letter-spacing: ${LetterSpacingValue._minus05},
    `,
    semiBold31: {
      FontWeight: FontWeightTheme.Label,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._31,
      letterSpacing: LetterSpacingValue._minus05,
    },
    semiBold39: {
      FontWeight: FontWeightTheme.Label,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._39,
      letterSpacing: LetterSpacingValue._minus05,
    },
    semiBold48: {
      FontWeight: FontWeightTheme.Label,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._48,
      letterSpacing: LetterSpacingValue._minus05,
    },
  },
  paragraphMedium: {
    paragraphMedium25: {
      FontWeight: FontWeightTheme.ParagraphMedium,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._25,
      letterSpacing: LetterSpacingValue._minus05,
    },
    paragraphMedium16: {
      FontWeight: FontWeightTheme.ParagraphMedium,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._16,
      letterSpacing: LetterSpacingValue._minus05,
    },
    paragraphMedium12: {
      FontWeight: FontWeightTheme.ParagraphMedium,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._12,
      letterSpacing: LetterSpacingValue._minus05,
    },
    paragraphMedium10: {
      FontWeight: FontWeightTheme.ParagraphMedium,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._10,
      letterSpacing: LetterSpacingValue._minus05,
    },
    paragraphMedium8: {
      FontWeight: FontWeightTheme.ParagraphMedium,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._8,
      letterSpacing: LetterSpacingValue._minus05,
    },
    paragraphMedium20: {
      FontWeight: FontWeightTheme.ParagraphMedium,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._20,
      letterSpacing: LetterSpacingValue._minus05,
    },
    paragraphMedium31: {
      FontWeight: FontWeightTheme.ParagraphMedium,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._31,
      letterSpacing: LetterSpacingValue._minus05,
    },
  },
  paragraphRegular: {
    paragraphRegular25: {
      FontWeight: FontWeightTheme.ParagraphRegular,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._25,
      letterSpacing: LetterSpacingValue._minus05,
    },
    paragraphRegular16: {
      FontWeight: FontWeightTheme.ParagraphRegular,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._16,
      letterSpacing: LetterSpacingValue._minus05,
    },
    paragraphRegular12: {
      fontWeight: FontWeightTheme.ParagraphRegular,
      lineHeight: LineHeightValue.Paragraph + "%",
      fontSize: FontSizeValue._12,
      letterSpacing: LetterSpacingValue._minus05,
    },
    paragraphRegular10: {
      fontWeight: FontWeightTheme.ParagraphRegular,
      lineHeight: LineHeightValue.Paragraph + "%",
      fontSize: FontSizeValue._10,
      letterSpacing: LetterSpacingValue._minus05,
    },
    paragraphRegular8: {
      fontWeight: FontWeightTheme.ParagraphRegular,
      lineHeight: LineHeightValue.Paragraph + "%",
      fontSize: FontSizeValue._8,
      letterSpacing: LetterSpacingValue._minus05,
    },
    paragraphRegular20: {
      fontWeight: FontWeightTheme.ParagraphRegular,
      lineHeight: LineHeightValue.Paragraph + "%",
      fontSize: FontSizeValue._20,
      letterSpacing: LetterSpacingValue._minus05,
    },
    paragraphRegular31: {
      FontWeight: FontWeightTheme.ParagraphRegular,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._31,
      letterSpacing: LetterSpacingValue._minus05,
    },
  },
};

export const TypographyComponent = {
  heading: {
    bold25: styled.p`
      font-weight: ${FontWeightTheme.Heading};
      line-height: ${LineHeightValue._140}%;
      font-size: ${FontSizeValue._25};
      letter-spacing: ${LetterSpacingValue._minus05};
    `,
    bold16: styled.p`
      font-weight: ${FontWeightTheme.Heading};
      line-height: ${LineHeightValue._140}%;
      font-size: ${FontSizeValue._16};
      letter-spacing: ${LetterSpacingValue._minus05};
    `,
    bold20: styled.p`
      font-weight: ${FontWeightTheme.Heading};
      line-height: ${LineHeightValue._140}%;
      font-size: ${FontSizeValue._20};
      letter-spacing: ${LetterSpacingValue._minus05};
    `,
    bold31: {
      FontWeight: FontWeightTheme.Heading,
      LineHeight: LineHeightValue._140,
      fontSize: FontSizeValue._31,
      letterSpacing: LetterSpacingValue._minus05,
    },
    bold39: {
      FontWeight: FontWeightTheme.Heading,
      LineHeight: LineHeightValue._140,
      fontSize: FontSizeValue._39,
      letterSpacing: LetterSpacingValue._minus05,
    },
    bold48: styled.p`
      font-weight: ${FontWeightTheme.Heading};
      line-height: ${LineHeightValue._140}%;
      font-size: ${FontSizeValue._48};
      letter-spacing: ${LetterSpacingValue._minus05};
      @media screen and (max-width: 768px) {
        ${TypographyStyle.heading.bold39}
      }
      @media screen and (max-width: 425px) {
        ${TypographyStyle.heading.bold25}
      }
    `,
    bold61: styled.p`
      font-weight: ${FontWeightTheme.Heading};
      line-height: ${LineHeightValue._140}%;
      font-size: ${FontSizeValue._61};
      letter-spacing: ${LetterSpacingValue._minus05};
      @media screen and (max-width: 768px) {
        ${TypographyStyle.heading.bold48}
      }
      @media screen and (max-width: 425px) {
        ${TypographyStyle.heading.bold39}
      }
    `,
  },
  label: {
    semiBold25: styled.p`
      font-weight: ${FontWeightTheme.Label};
      //line-height: ${LineHeightValue.Paragraph};
      font-size: ${FontSizeValue._25};
      letter-spacing: ${LetterSpacingValue._minus05};
    `,
    semiBold16: styled.p`
      font-weight: ${FontWeightTheme.Label};
      //line-height: ${LineHeightValue.Paragraph};
      font-size: ${FontSizeValue._16};
      letter-spacing: ${LetterSpacingValue._minus05};
    `,
    semiBold20: styled.p`
      font-weight: ${FontWeightTheme.Label};
      //line-height: ${LineHeightValue.Paragraph};
      font-size: ${FontSizeValue._20};
      letter-spacing: ${LetterSpacingValue._minus05};
    `,
    semiBold31: styled.p`
      font-weight: ${FontWeightTheme.Label};
      // line-height: ${LineHeightValue.Paragraph};
      font-size: ${FontSizeValue._31};
      letter-spacing: ${LetterSpacingValue._minus05};
    `,
    semiBold39: styled.p`
      font-weight: ${FontWeightTheme.Label};
      // line-height: ${LineHeightValue.Paragraph};
      font-size: ${FontSizeValue._39};
      letter-spacing: ${LetterSpacingValue._minus05};
    `,
    semiBold48: {
      FontWeight: FontWeightTheme.Label,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._48,
      letterSpacing: LetterSpacingValue._minus05,
    },
  },
  paragraphMedium: {
    paragraphMedium25: {
      FontWeight: FontWeightTheme.ParagraphMedium,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._25,
      letterSpacing: LetterSpacingValue._minus05,
    },
    paragraphMedium16: styled.p`
      font-weight: ${FontWeightTheme.ParagraphMedium};
      // line-height: ${LineHeightValue.Paragraph};
      font-size: ${FontSizeValue._16};
      letter-spacing: ${LetterSpacingValue._minus05};
    `,
    paragraphMedium12: styled.p`
      font-weight: ${FontWeightTheme.ParagraphMedium};
      // line-height: ${LineHeightValue.Paragraph}px;
      font-size: ${FontSizeValue._12};
      letter-spacing: ${LetterSpacingValue._minus05};
    `,
    paragraphMedium10: {
      FontWeight: FontWeightTheme.ParagraphMedium,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._10,
      letterSpacing: LetterSpacingValue._minus05,
    },
    paragraphMedium8: styled.p`
      font-weight: ${FontWeightTheme.ParagraphMedium};
      // line-height: ${LineHeightValue.Paragraph}px;
      font-size: ${FontSizeValue._8};
      letter-spacing: ${LetterSpacingValue._minus05};
    `,
    paragraphMedium20: {
      FontWeight: FontWeightTheme.ParagraphMedium,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._20,
      letterSpacing: LetterSpacingValue._minus05,
    },
    paragraphMedium31: {
      FontWeight: FontWeightTheme.ParagraphMedium,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._31,
      letterSpacing: LetterSpacingValue._minus05,
    },
  },
  paragraphRegular: {
    paragraphRegular25: {
      FontWeight: FontWeightTheme.ParagraphRegular,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._25,
      letterSpacing: LetterSpacingValue._minus05,
    },
    paragraphRegular16: styled.p`
      font-weight: ${FontWeightTheme.ParagraphRegular};
      //line-height: ${LineHeightValue.Paragraph}%;
      font-size: ${FontSizeValue._16};
      letter-spacing: ${LetterSpacingValue._minus05};
      @medai screen and(max-width:425px) {
        ${TypographyStyle.paragraphRegular.paragraphRegular12}
      }
    `,
    paragraphRegular12: styled.p`
      font-weight: ${FontWeightTheme.ParagraphRegular};
      line-height: ${LineHeightValue.Paragraph}%;
      font-size: ${FontSizeValue._12};
      letter-spacing: ${LetterSpacingValue._minus05};
    `,
    paragraphRegular10: {
      FontWeight: FontWeightTheme.ParagraphRegular,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._10,
      letterSpacing: LetterSpacingValue._minus05,
    },
    paragraphRegular8: {
      FontWeight: FontWeightTheme.ParagraphRegular,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._8,
      letterSpacing: LetterSpacingValue._minus05,
    },
    paragraphRegular20: {
      FontWeight: FontWeightTheme.ParagraphRegular,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._20,
      letterSpacing: LetterSpacingValue._minus05,
    },
    paragraphRegular31: {
      FontWeight: FontWeightTheme.ParagraphRegular,
      LineHeight: LineHeightValue.Paragraph,
      fontSize: FontSizeValue._31,
      letterSpacing: LetterSpacingValue._minus05,
    },
  },
};
