import { Icon } from "@iconify/react";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import MenuHorizontal from "components/Layout/Menu/MenuHorizontal";
import { defaultColorTheme, grayScaleTheme } from "components/Layout/Theme";
// import SockJS from "sockjs-client";
import { over } from "webstomp-client";
import {
  ShadowTheme,
  TypographyComponent,
} from "components/StyleComponent/FontStyle";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { AuthContext } from "context/authContext";
import { useNavigate } from "react-router";
import { BorderButton } from "components/StyleComponent/BorderButton";
import { Spacer } from "components/StyleComponent/Spacer";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import MenuIconHorizontal from "components/Layout/Menu/MenuIconHorizontal";
import {
  FlexBackground,
  FlexCenter,
  GridColumn4to2,
} from "components/StyleComponent/LayoutStyle";
import VerticalNameTagRadiusBox from "components/StyleComponent/VerticalNameTagRadiusBox";
import axios from "axios";
import { LanguageContext } from "context/languageContext";
import { dataObjectType } from "types/accident";
import styled from "@emotion/styled";
type Item = {
  dataKey: string;
  dataObject: dataObjectType;
};

const AccidentListContainer = styled.div`
  overflow-y: scroll;
  height: 85%;
  padding: 0 5px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const DetailListContainer = styled.div`
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const DashBoard: FunctionComponent = function () {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAUMOlJCMKz9JhtLsahW_XVOCOFNqoG7Ys",
  });
  const containerStyle = {
    width: "100%",
    height: "95%",
    borderRadius: "8px",
  };
  const navigation = useNavigate();
  const [selectedButton, setSelectedButton] = useState<number | null>(0);
  const [hoverButton, setHoverButton] = useState<number | null>(null);
  const [items, setItems] = useState<Item[]>([]);
  const [originItems, setOriginItems] = useState<Item[]>([]);
  const userInfo = useContext(AuthContext);
  const [info, setInfo] = useState<dataObjectType>({
    accel: "-",
    address: "-",
    date: "-",
    idToken: "-",
    location: "36.8098309/ 127.1442811",
    pty: 0,
    roll: "-",
    sky: 0,
    t1h: 0,
    type: "-",
    yaw: "-",
    name: "-",
    carNumber: "-",
    parentPhoneNumber: "-",
    insuranceCompany: "-",
  });

  const [record, setRecord] = useState<{
    label: string;
    value: number;
    color: string;
  }>({
    label: "",
    value: 0,
    color: "",
  });
  useEffect(() => {
    window.location.replace("https://myren.pro/controllserver");
  }, []);
  /* 로그인 여부 확인하는 로직 */
  useEffect(() => {
    if (userInfo?.uid !== "Rb1PXfJJWPTwBfUcDrwMdsdfUqc2") {
      navigation("/serverlogin");
    }
  }, [navigation, userInfo]);

  /*onChildAdded 함수를 사용하면 DB에 새로운 정보가 저정되었을 때, dbRef에 있는 AccidentRecord 데이터 불러온다.*/
  useEffect(() => {
    axios
      .get("http://3.36.166.3:8080/api/accident-record/all")
      .then((Response) => {
        console.log(Response);
        const dataKey = Object.keys(Response.data.data);
        const dataValue = Object.values(Response.data.data);
        const Items: Item[] = dataKey.map((key, index) => ({
          dataKey: key,
          dataObject: dataValue[index] as dataObjectType,
        }));
        Items.reverse();
        setItems(Items);
        setOriginItems(Items);
        setInfo(Items[0].dataObject);
      })
      .catch((Error) => {
        console.log(Error);
      });
  }, []);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let testtest = 0;
  // useEffect(() => {
  //   const isHttps = window.location.protocol === "https:";
  //   console.log(isHttps);
  //   const protocol = isHttps ? "wss" : "ws";
  //   const socket = new WebSocket(`http://3.36.166.3/ws`);
  //   const stompClient = over(socket);

  //   stompClient.connect({}, (frame) => {
  //     stompClient.subscribe("/topic/accidentRecords", (message) => {
  //       console.log("success");
  //       if (message.body) {
  //         const Item = {
  //           dataKey: items.length.toString(),
  //           dataObject: JSON.parse(message.body),
  //         };
  //         if (testtest !== Item.dataObject.idx) {
  //           setItems((items) => [Item, ...items]);
  //           testtest = Item.dataObject.idx;
  //         }

  //         setInfo(Item.dataObject);
  //         setSelectedButton(0);
  //       }
  //     });
  //   });

  //   return () => {
  //     if (stompClient.connected) {
  //       stompClient.disconnect(() => {}, {});
  //     }
  //   };
  // }, []);

  const { lang } = useContext(LanguageContext);
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <div style={{ display: "flex" }}>
      {windowWidth <= 1200 ? (
        <MenuIconHorizontal originItems={originItems} />
      ) : (
        <MenuHorizontal originItems={originItems} />
      )}
      {/* <MenuHorizontal /> */}
      <FlexBackground style={{ width: "100%" }}>
        <div style={{ width: "50%" }}>
          <div
            style={{
              boxShadow: ShadowTheme._16,
              borderRadius: "40px",
              backgroundColor: "white",
              height: "96.7vh",
              margin: "16px 16px",
              padding: "32px",
            }}
          >
            <FlexCenter style={{ marginBottom: "16px" }}>
              <Icon
                icon={"mdi:location"}
                fontSize={"25px"}
                style={{ marginRight: "16px" }}
              />
              <TypographyComponent.label.semiBold20>
                {lang.dashboardTxt.contentTxt.location}
              </TypographyComponent.label.semiBold20>
            </FlexCenter>
            <GoogleMap
              zoom={16}
              mapContainerStyle={containerStyle}
              center={{
                lat: Number(info.location.split("/")[0]),
                lng: Number(info.location.split("/")[1]),
              }}
              mapContainerClassName="map-container"
              options={{ disableDefaultUI: true }}
            >
              <MarkerF
                position={{
                  lat: Number(info.location.split("/")[0]),
                  lng: Number(info.location.split("/")[1]),
                }}
                icon={{ url: "/img/MapMarker.png", scale: 5 }}
              />
            </GoogleMap>
          </div>
        </div>
        <div style={{ width: "58%" }}>
          <div
            style={{
              boxShadow: ShadowTheme._16,
              boxSizing: "border-box",
              borderRadius: "40px",
              backgroundColor: "white",
              height: "49vh",
              margin: "16px 16px",
              padding: "24px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <Icon
                icon={"priority_high"}
                fontSize={"25px"}
                style={{ marginRight: "16px" }}
              />
              <TypographyComponent.label.semiBold20>
                {lang.dashboardTxt.contentTxt.accidentList}
              </TypographyComponent.label.semiBold20>
            </div>
            <AccidentListContainer
              style={{
                overflowY: "scroll",
                height: "85%",
                padding: "0 5px",
              }}
            >
              {items.map((item, index) => {
                return (
                  <div>
                    <BorderButton
                      key={index}
                      onMouseEnter={() => setHoverButton(index)}
                      onMouseLeave={() => setHoverButton(null)}
                      onClick={() => {
                        setInfo(item.dataObject);
                        setSelectedButton(index);
                        Number(item.dataObject.accel) < 250
                          ? setRecord({
                              label: lang.detailRecordTxt.AccidentRecord[0],
                              value: 33,
                              color: "#0ECE49",
                            })
                          : Number(item.dataObject.accel) > 400
                          ? setRecord({
                              label: lang.detailRecordTxt.AccidentRecord[2],
                              value: 100,
                              color: "#F0273D",
                            })
                          : setRecord({
                              label: lang.detailRecordTxt.AccidentRecord[1],
                              value: 66,
                              color: "#F36B1E",
                            });
                        // getItem(item.dataObject.idToken);
                      }}
                      style={{
                        borderRadius: "8px",
                        backgroundColor:
                          selectedButton === index
                            ? grayScaleTheme.grayScaleColor500
                            : hoverButton === index
                            ? grayScaleTheme.grayScaleColor50
                            : grayScaleTheme.grayScaleColor100,
                        padding: "12px",
                        marginBottom: "12px",
                        boxShadow:
                          hoverButton === index
                            ? ShadowTheme._12
                            : ShadowTheme._2,
                        width: "100%",
                      }}
                      borderRadius={"8px"}
                      textColor={""}
                      backgroundColor={defaultColorTheme.White}
                      paddingHorizontal="12px"
                      paddingVertical="12px"
                      marginBottom="20px"
                    >
                      <div
                        style={{
                          display: "flex",
                          paddingLeft: "12px",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              width: "fit-content",
                              display: "flex",
                              alignItems: "center",
                              color:
                                selectedButton === index
                                  ? defaultColorTheme.White
                                  : grayScaleTheme.grayScaleColor800,
                            }}
                          >
                            <Icon
                              icon={"fa6-solid:car-on"}
                              style={{
                                color:
                                  selectedButton === index
                                    ? defaultColorTheme.White
                                    : grayScaleTheme.grayScaleColor800,
                              }}
                            />
                            <Spacer horizontal={false} space={"8px"} />
                            {item.dataObject.type}

                            <div
                              style={{
                                color:
                                  selectedButton === index
                                    ? defaultColorTheme.White
                                    : grayScaleTheme.grayScaleColor800,
                                textAlign: "initial",
                              }}
                            >
                              {item.dataObject.address}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "28%",
                          }}
                        >
                          <div
                            style={{
                              color:
                                selectedButton === index
                                  ? defaultColorTheme.White
                                  : grayScaleTheme.grayScaleColor800,
                            }}
                          >
                            {item.dataObject.date.slice(0, 10)}
                          </div>
                          <div
                            style={{
                              color:
                                selectedButton === index
                                  ? defaultColorTheme.White
                                  : grayScaleTheme.grayScaleColor800,
                            }}
                          >
                            {item.dataObject.date.slice(
                              11,
                              item.dataObject.date.length
                            )}
                          </div>
                          <Icon
                            icon={"ri:arrow-right-s-line"}
                            style={{
                              color:
                                selectedButton === index
                                  ? defaultColorTheme.White
                                  : grayScaleTheme.grayScaleColor800,
                            }}
                          />
                        </div>
                      </div>
                    </BorderButton>
                  </div>
                );
              })}
            </AccidentListContainer>
          </div>
          <div
            style={{
              boxShadow: ShadowTheme._16,
              borderRadius: "40px",
              backgroundColor: "white",
              height: "46vh",
              margin: "16px 16px",
              padding: "32px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                marginBottom: "16px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <Icon
                  icon={"material-symbols:grid-on-sharp"}
                  fontSize={"25px"}
                  style={{ marginRight: "16px" }}
                />
                <TypographyComponent.label.semiBold20>
                  {lang.dashboardTxt.contentTxt.details}
                </TypographyComponent.label.semiBold20>
              </div>
              <Spacer horizontal={true} space={"8px"} />
              <DetailListContainer>
                {/* row1 */}
                <GridColumn4to2>
                  <VerticalNameTagRadiusBox
                    children={info.name}
                    LabelText={lang.dashboardTxt.detailsTxt.user}
                  />
                  <VerticalNameTagRadiusBox
                    children={info.parentPhoneNumber}
                    LabelText={lang.dashboardTxt.detailsTxt.parentsContacts}
                  />
                  {/* row2 */}
                  <VerticalNameTagRadiusBox
                    children={info.date}
                    LabelText={lang.dashboardTxt.detailsTxt.accidentDate}
                  />
                  <VerticalNameTagRadiusBox
                    children={info.address}
                    LabelText={lang.dashboardTxt.detailsTxt.location}
                  />
                  <VerticalNameTagRadiusBox
                    children={info.location}
                    LabelText={lang.dashboardTxt.detailsTxt.rollyaw}
                  />
                  <VerticalNameTagRadiusBox
                    children={info.insuranceCompany}
                    LabelText={lang.dashboardTxt.detailsTxt.insurance}
                  />
                  <VerticalNameTagRadiusBox
                    children={info.carNumber}
                    LabelText={lang.dashboardTxt.detailsTxt.carNumber}
                  />
                  <VerticalNameTagRadiusBox
                    children={
                      info.pty == 0
                        ? lang.detailRecordTxt.skyState[info.sky]
                        : lang.detailRecordTxt.rainState[info.pty]
                    }
                    LabelText={lang.dashboardTxt.detailsTxt.weather}
                  />
                  <VerticalNameTagRadiusBox
                    children={info.t1h.toString() + "°C"}
                    LabelText={lang.dashboardTxt.detailsTxt.temperature}
                  />
                  <VerticalNameTagRadiusBox
                    children={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "168px",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            width: "32px",
                            height: "32px",
                            display: "flex",
                            paddingRight: "5px",
                          }}
                        >
                          <CircularProgressbar
                            counterClockwise={true}
                            strokeWidth={20}
                            value={record.value}
                            styles={buildStyles({
                              textColor: "red",
                              pathColor: record.color,
                              trailColor: defaultColorTheme.White,
                            })}
                          />
                        </div>
                        <div>
                          <TypographyComponent.paragraphMedium.paragraphMedium16
                            style={{ wordBreak: "keep-all", minWidth: "30px" }}
                          >
                            {info.accel}
                          </TypographyComponent.paragraphMedium.paragraphMedium16>
                        </div>
                        <div>
                          <TypographyComponent.paragraphMedium.paragraphMedium12
                            style={{ wordBreak: "keep-all" }}
                          >
                            {Number(info.accel) < 250
                              ? lang.detailRecordTxt.AccidentRecord[0]
                              : Number(info.accel) > 400
                              ? lang.detailRecordTxt.AccidentRecord[2]
                              : lang.detailRecordTxt.AccidentRecord[1]}
                          </TypographyComponent.paragraphMedium.paragraphMedium12>
                        </div>
                      </div>
                    }
                    LabelText={lang.dashboardTxt.detailsTxt.acceleration}
                  />
                </GridColumn4to2>
              </DetailListContainer>
            </div>
          </div>
        </div>
      </FlexBackground>
    </div>
  );
};

export default DashBoard;
