import { FunctionComponent, useState } from "react";
import { languageType } from "types/language";
import Language from "../JsonData/Language.json";
import { LanguageContext } from "context/languageContext";

const LangProvider: FunctionComponent<{ children: React.ReactNode }> = (
  props
) => {
  const [lang, setLang] = useState<languageType>(Language.language.en);
  const updateLang = (langType: string) => {
    switch (langType) {
      case "de":
        setLang(Language.language.de);
        break;
      case "en":
        setLang(Language.language.en);
        break;
      case "kr":
        setLang(Language.language.kr);
        break;
    }
  };

  return (
    <LanguageContext.Provider value={{ lang, updateLang }}>
      {props.children}
    </LanguageContext.Provider>
  );
};

export default LangProvider;
