import { Icon } from "@iconify/react";
import Footer from "components/Layout/Footer";
import {
  defaultColorTheme,
  grayScaleTheme,
  mainTheme,
} from "components/Layout/Theme";
import {
  ContentImgBlock,
  ContentTextBlock,
  ContentsBlock,
  HeaderBlock,
  HiddenButtonBlock,
  LogoBlock,
} from "components/Style/MainEng.style";
import {
  TypographyComponent,
  TypographyStyle,
} from "components/StyleComponent/FontStyle";
import { FunctionComponent, useState } from "react";
import Language from "../JsonData/Language.json";

const MainEng: FunctionComponent = function () {
  const [language, setLanguageData] = useState(Language.language.en);
  return (
    <>
      <HeaderBlock>
        <LogoBlock src="./img/Logo.png" alt="" />
        <HiddenButtonBlock
          style={{
            ...TypographyStyle.paragraphMedium.paragraphMedium20,
          }}
        >
          <button
            style={{
              ...TypographyStyle.paragraphMedium.paragraphMedium20,
              backgroundColor: defaultColorTheme.White,
              // padding: "0",
            }}
            onClick={() => {
              setLanguageData(Language.language.en);
              localStorage.setItem("language", "en");
            }}
          >
            EN
          </button>
          /
          <button
            style={{
              ...TypographyStyle.paragraphMedium.paragraphMedium20,
              backgroundColor: defaultColorTheme.White,
              // padding: "0",
            }}
            onClick={() => {
              setLanguageData(Language.language.de);
              localStorage.setItem("language", "de");
            }}
          >
            DE
          </button>
          /
          <button
            style={{
              ...TypographyStyle.paragraphMedium.paragraphMedium20,
              backgroundColor: defaultColorTheme.White,
              // padding: "0",
            }}
            onClick={() => {
              setLanguageData(Language.language.kr);
              localStorage.setItem("language", "kr");
            }}
          >
            KR
          </button>
        </HiddenButtonBlock>
      </HeaderBlock>
      <ContentsBlock>
        <ContentTextBlock>
          <TypographyComponent.heading.bold48>
            {language.mainTxt.mainTitle}
          </TypographyComponent.heading.bold48>
          <TypographyComponent.heading.bold61
            style={{ color: mainTheme.mainColor500 }}
          >
            {language.mainTxt.title}
          </TypographyComponent.heading.bold61>
          <TypographyComponent.paragraphRegular.paragraphRegular16
            style={{
              color: grayScaleTheme.grayScaleColor500,
              paddingTop: "16px",
              wordBreak: "keep-all",
            }}
          >
            {language.mainTxt.explain1}
            <br />
            {/* Myren ist eine Sicherheitsmarke, die sich aus "My" und "Siren"
            <br />
          zusammensetzt. */}
            {/* Wir führen Sie durch die plötzliche Situation des Unfalls und <br />
            wie Sie damit umgehen können. */}
            {language.mainTxt.explain2}
          </TypographyComponent.paragraphRegular.paragraphRegular16>
          <div>
            <img src="./img/MWC.png" alt="" style={{ width: "30%" }} />
            <img src="./img/CES.png" alt="" style={{ width: "30%" }} />
            <img src="./img/IFA.png" alt="" style={{ width: "20%" }} />
          </div>
        </ContentTextBlock>
        <ContentImgBlock>
          <div>
            <img src="./img/MWC.png" alt="" style={{ width: "30%" }} />
            <img src="./img/CES.png" alt="" style={{ width: "30%" }} />
            <img src="./img/IFA.png" alt="" style={{ width: "20%" }} />
          </div>
          <img src="./img/GlobalMain.png" alt="" />
        </ContentImgBlock>
      </ContentsBlock>
      <Footer langu={language.footerTxt} />
      {/* <img src="./img/1.png" alt="img1" />
      <img src="./img/2.png" alt="img1" />
      <img src="./img/3.png" alt="img1" />
      <img src="./img/4.png" alt="img1" />
      <img src="./img/5.png" alt="img1" />
      <img src="./img/6.png" alt="img1" />
      <img src="./img/7.png" alt="img1" />
      <img src="./img/8.png" alt="img1" />
      <img src="./img/9.png" alt="img1" /> */}
    </>
  );
};

export default MainEng;
