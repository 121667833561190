import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { MenuHorizontalWrapper, MenuLogoImg } from "../Menu.style";
import { DividerLine } from "components/StyleComponent/DividerLine";
import { ButtonStyle, defaultColorTheme, grayScaleTheme } from "../Theme";
import { Icon } from "@iconify/react";
import {
  ShadowTheme,
  TypographyStyle,
} from "components/StyleComponent/FontStyle";
import { BorderButton } from "components/StyleComponent/BorderButton";
import ButtonContent from "../ButtonContents";
import Modal from "../Modal";
import CsvDownload from "react-json-to-csv";
import { HiddenButtonBlock } from "components/Style/MainEng.style";
import { LanguageContext } from "context/languageContext";
import Profile from "../Profile";
export type sortType = {
  car: boolean;
  bicycle: boolean;
  wheelchair: boolean;
  pedestrian: boolean;
};
export type Item = {
  dataKey: string;
  dataObject: dataObjectType;
};

export type dataObjectType = {
  accel: string;
  address: string;
  date: string;
  idToken: string;
  pty: number;
  location: string;
  roll: string;
  sky: number;
  t1h: number;
  type: string;
  yaw: string;
};
export type MenuHorizontalProps = {
  originItems: Item[];
};
const dateObject = new Date();
const Year = dateObject.getFullYear().toString();
const Month = (dateObject.getMonth() + 1).toString();
const Day = dateObject.getDate().toString();

const MenuHorizontal: FunctionComponent<MenuHorizontalProps> = function ({
  // modalSorting,
  originItems,
}) {
  const [items, setItems] = useState<Item[]>([]);
  const [isOpenModal, setOpenModal] = useState(false);
  const [totalModalSorting, setTotalModalSorting] = useState(true);
  const [filteredItems, setFiltereditems] = useState<Item[]>([]);
  const [CSV, setCSV] = useState<dataObjectType[]>([]);
  const csvHeader = [
    "가속도,주소,날짜,회원 토큰,위치정보,Roll,사고유형,Yaw, ,온도,주행 타입, Roll",
  ];
  const CSVFileName =
    "MyRen accident data report : " + Year + "_" + Month + "_" + Day;
  const onClickToggleModal = useCallback(() => {
    setOpenModal(!isOpenModal);
  }, [isOpenModal]);

  const checkTotalSorting = (props: sortType) => {
    const isAllTrue = Object.values(props).every((value) => value === true);
    return isAllTrue;
  };
  const [modalSorting, setModalSorting] = useState<sortType>({
    car: true,
    bicycle: true,
    wheelchair: true,
    pedestrian: true,
  });

  /* modal sorting 하면 추출될 파일 filtering */
  useEffect(() => {
    if (Object.values(modalSorting).every((value) => value === true)) {
      setTotalModalSorting(true);
    } else {
      setTotalModalSorting(false);
    }

    if (isOpenModal) {
      sortingDB({
        Item: originItems,
        Sorting: modalSorting,
        Type: true,
      });

      const newCSV: dataObjectType[] = [];
      filteredItems.forEach((data) => {
        newCSV.push(data.dataObject);
      });
      setCSV(newCSV);
    }
  }, [modalSorting, originItems, isOpenModal, filteredItems]); //
  /* DB sorting 한것 적용해서 화면에 뿌려주기 위한 items 변경하는 함수 */
  const sortingDB = (props: {
    Item: Item[];
    Sorting: sortType;
    Type: boolean;
  }) => {
    if (props.Type) {
      setFiltereditems(
        props.Item.filter((item) => {
          if (props.Sorting.car && item.dataObject.type === "자동차") {
            return true;
          }
          if (props.Sorting.bicycle && item.dataObject.type === "자전거") {
            return true;
          }
          if (props.Sorting.wheelchair && item.dataObject.type === "휠체어") {
            return true;
          }
          if (props.Sorting.pedestrian && item.dataObject.type === "보행자") {
            return true;
          }
          return false;
        })
      );
    } else {
      setItems(
        props.Item.filter((item) => {
          if (props.Sorting.car && item.dataObject.type === "자동차") {
            return true;
          }
          if (props.Sorting.bicycle && item.dataObject.type === "자전거") {
            return true;
          }
          if (props.Sorting.wheelchair && item.dataObject.type === "휠체어") {
            return true;
          }
          if (props.Sorting.pedestrian && item.dataObject.type === "보행자") {
            return true;
          }
          return false;
        })
      );
    }
  };

  const { lang, updateLang } = useContext(LanguageContext);
  return (
    <>
      {isOpenModal && (
        <Modal onClickToggleModal={onClickToggleModal}>
          <div
            style={{
              padding: "28px 32px",
              borderRadius: "16px",
              zIndex: 10000,
              backgroundColor: defaultColorTheme.White,
              maxWidth: "90%",
              maxHeight: "90%",
              minWidth: "404px",
              minHeight: "294px",
              boxShadow: ShadowTheme._16,
            }}
          >
            <div
              style={{
                borderBottom: "1px solid ",
                borderBottomColor: grayScaleTheme.grayScaleColor600,
                paddingBottom: "16px",
              }}
            >
              데이터 목록 CSV 파일로 내려받기
            </div>
            {/* sort item 버튼 */}
            <div>
              <div style={{ paddingTop: "12px", paddingBottom: "24px" }}>
                내려받을 데이터 유형을 모두 선택해 주세요
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      maxWidth: "80%",
                      alignItems: "center",
                      display: "flex",
                      // justifyContent: "space-between",
                      paddingBottom: "8px",
                      paddingTop: "16px",
                    }}
                  >
                    <BorderButton
                      onClick={() => {
                        if (checkTotalSorting(modalSorting)) {
                          setModalSorting({
                            car: false,
                            bicycle: false,
                            wheelchair: false,
                            pedestrian: false,
                          });
                        } else {
                          setModalSorting({
                            car: true,
                            bicycle: true,
                            wheelchair: true,
                            pedestrian: true,
                          });
                          setItems(originItems);
                        }
                      }}
                      borderRadius={""}
                      textColor={""}
                      backgroundColor={""}
                      style={
                        totalModalSorting
                          ? {
                              ...ButtonStyle.EnabledChecked,
                              // padding: "4px 12px",
                              fontSize: "16px",
                            }
                          : {
                              ...ButtonStyle.Enabled,
                              // padding: "4px 12px",
                              fontSize: "16px",
                            }
                      }
                    >
                      <>
                        {totalModalSorting ? (
                          <Icon icon={"material-symbols:check"} />
                        ) : null}
                        전체
                      </>
                    </BorderButton>
                    <BorderButton
                      onClick={() => {
                        setModalSorting((prevModalSorting) => ({
                          ...prevModalSorting,
                          car: !prevModalSorting.car,
                        }));
                      }}
                      borderRadius={""}
                      textColor={""}
                      backgroundColor={""}
                      style={
                        modalSorting.car
                          ? {
                              ...ButtonStyle.EnabledChecked,
                              // padding: "4px 12px",
                              fontSize: "16px",
                            }
                          : {
                              ...ButtonStyle.Enabled,
                              // padding: "4px 12px",
                              fontSize: "16px",
                            }
                      }
                    >
                      <>
                        {modalSorting.car ? (
                          <Icon icon={"material-symbols:check"} />
                        ) : null}
                        자동차
                      </>
                    </BorderButton>
                    <BorderButton
                      onClick={() => {
                        setModalSorting((prevModalSorting) => ({
                          ...prevModalSorting,
                          bicycle: !prevModalSorting.bicycle,
                        }));
                      }}
                      borderRadius={""}
                      textColor={""}
                      backgroundColor={""}
                      style={
                        modalSorting.bicycle
                          ? {
                              ...ButtonStyle.EnabledChecked,
                              // padding: "4px 12px",
                              fontSize: "16px",
                            }
                          : {
                              ...ButtonStyle.Enabled,
                              // padding: "4px 12px",
                              fontSize: "16px",
                            }
                      }
                    >
                      <>
                        {modalSorting.bicycle ? (
                          <Icon icon={"material-symbols:check"} />
                        ) : null}
                        자전거
                      </>
                    </BorderButton>
                  </div>
                  <div
                    style={{
                      // width:
                      //   modalSorting.wheelchair || modalSorting.pedestrian
                      //     ? "54%"
                      //     : "44%",
                      alignItems: "center",
                      display: "flex",
                      // justifyContent: "space-between",
                      paddingBottom: "8px",
                      maxWidth: "74%",
                    }}
                  >
                    <BorderButton
                      onClick={() => {
                        setModalSorting((prevModalSorting) => ({
                          ...prevModalSorting,
                          wheelchair: !prevModalSorting.wheelchair,
                        }));
                      }}
                      borderRadius={""}
                      textColor={""}
                      backgroundColor={""}
                      style={
                        modalSorting.wheelchair
                          ? {
                              ...ButtonStyle.EnabledChecked,
                              // padding: "4px 12px",
                              fontSize: "16px",
                            }
                          : {
                              ...ButtonStyle.Enabled,
                              // padding: "4px 12px",
                              fontSize: "16px",
                            }
                      }
                    >
                      <>
                        {modalSorting.wheelchair ? (
                          <Icon icon={"material-symbols:check"} />
                        ) : null}
                        휠체어
                      </>
                    </BorderButton>
                    <BorderButton
                      onClick={() => {
                        setModalSorting((prevModalSorting) => ({
                          ...prevModalSorting,
                          pedestrian: !prevModalSorting.pedestrian,
                        }));
                      }}
                      borderRadius={""}
                      textColor={""}
                      backgroundColor={""}
                      style={
                        modalSorting.pedestrian
                          ? {
                              ...ButtonStyle.EnabledChecked,
                              // padding: "4px 12px",
                              fontSize: "16px",
                            }
                          : {
                              ...ButtonStyle.Enabled,
                              // padding: "4px 12px",
                              fontSize: "16px",
                            }
                      }
                    >
                      <>
                        {modalSorting.pedestrian ? (
                          <Icon icon={"material-symbols:check"} />
                        ) : null}
                        보행자
                      </>
                    </BorderButton>
                  </div>
                </div>
              </div>
            </div>
            {/* 추출 버튼 */}
            <div
              style={{
                display: "flex",
                flexFlow: "row-reverse",
              }}
            >
              <BorderButton
                onClick={() => {
                  sortingDB({
                    Item: originItems,
                    Sorting: modalSorting,
                    Type: true,
                  });
                }}
                style={
                  Object.values(modalSorting).find((value) => value === true)
                    ? {
                        ...ButtonStyle.EnabledChecked,
                        borderRadius: "8px",
                        padding: "8px 16px",
                      }
                    : {
                        ...ButtonStyle.Disabled,
                        borderRadius: "8px",
                        padding: "8px 16px",
                      }
                }
              >
                <CsvDownload
                  data={CSV}
                  filename={CSVFileName}
                  headers={csvHeader}
                  delimiter=","
                  style={
                    Object.values(modalSorting).find((value) => value === true)
                      ? {
                          ...ButtonStyle.EnabledChecked,
                          // borderRadius: "8px",
                          // padding: "0",
                          // border: "none ",
                          // borderRadius: 0,
                        }
                      : {
                          ...ButtonStyle.Disabled,
                          // borderRadius: "8px",
                          // padding: "8px 16px",
                        }
                  }
                  // style={{ color: grayScaleTheme.grayScaleColor500 }}
                >
                  CSV 파일 내려받기
                </CsvDownload>
              </BorderButton>
            </div>
          </div>
        </Modal>
      )}
      <MenuHorizontalWrapper style={{ width: "15%", height: "100vh" }}>
        <MenuLogoImg src="/img/Logo.png" />
        <DividerLine />
        <Profile />
        <DividerLine />
        <div style={{ display: "flex", flexDirection: "column" }}>
          {lang.dashboardTxt.menuTxt.list}
          <BorderButton
            style={{ backgroundColor: "white", margin: "0", padding: "0" }}
            onClick={() => {}}
          >
            <ButtonContent
              iconSrc="mdi:exclamation"
              title={lang.dashboardTxt.contentTxt.accidentList}
              noticeFlag={false}
            />
          </BorderButton>
        </div>
        <DividerLine />
        <div>
          <HiddenButtonBlock
            style={{
              ...TypographyStyle.paragraphMedium.paragraphMedium20,
            }}
          >
            <button
              style={{
                ...TypographyStyle.paragraphMedium.paragraphMedium20,
                backgroundColor: defaultColorTheme.White,
                // padding: "0",
              }}
              onClick={() => updateLang("de")}
            >
              DE
            </button>
            /
            <button
              style={{
                ...TypographyStyle.paragraphMedium.paragraphMedium20,
                backgroundColor: defaultColorTheme.White,
                // padding: "0",
              }}
              onClick={() => updateLang("en")}
            >
              EN
            </button>
            /
            <button
              style={{
                ...TypographyStyle.paragraphMedium.paragraphMedium20,
                backgroundColor: defaultColorTheme.White,
                // padding: "0",
              }}
              onClick={() => updateLang("kr")}
            >
              KR
            </button>
          </HiddenButtonBlock>
        </div>
      </MenuHorizontalWrapper>
    </>
  );
};

export default MenuHorizontal;
