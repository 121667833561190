import { TypographyStyle } from "components/StyleComponent/FontStyle";
import styled from "styled-components";

export const HeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  padding: 24px 40px;
  @media screen and (max-width: 768px) {
    // height: 20vh;
    padding: 20px 32px;
  }
  @media screen and (max-width: 425px) {
    // height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
  }
`;

export const LogoBlock = styled.img`
  width: 112.8px;
  height: 24px;
  @media screen and (max-width: 768px) {
    width: 94px;
    height: 20px;
  }
  @media screen and (max-width: 425px) {
    width: 75.2px;
    height: 16px;
  }
`;

export const HiddenImgBlock = styled.img`
  display: none;
  @media screen and (max-width: 425px) {
    display: initial;
    width: 44px;
  }
`;
export const HiddenButtonBlock = styled.div`
  padding: 0;
  ${TypographyStyle.paragraphMedium.paragraphMedium20}
  @media screen and (max-width: 425px) {
    ${TypographyStyle.paragraphMedium.paragraphMedium16}
  }
`;

export const ContentsBlock = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 130px 56px;

  @media screen and (max-width: 768px) {
    height: inherit;
    flex-direction: column;
    padding: 0;
    padding-bottom: 56px;
  }
`;

export const ContentTextBlock = styled.div`
  width: 40%;
  div:last-child {
    display: flex;
    @media screen and (max-width: 425px) {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    padding-top: 56px;
    // padding-left: 52px;
    width: 70%;
  }
  @media screen and (max-width: 425px) {
    padding: 24px 16px;
  }
`;
export const ContentImgBlock = styled.div`
  div:first-child {
    display: none;
    @media screen and (max-width: 425px) {
      display: flex;
      height: 48px;
    }
  }
  width: 50%;
  @media screen and (max-width: 768px) {
    padding: 72px 52px;
    width: 100%;
  }
  @media screen and (max-width: 425px) {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 20px;
  }
`;
